import React from 'react';

const VeeamValues = ({ imageUrl }: { imageUrl: string }) => {
  return (
    <div className="card card-values">
      <div className="card-header px-0 pb-0 justify-content-center">
        <img src={imageUrl} alt="" className="img-fluid" />
      </div>
    </div>
  );
};

export default VeeamValues;
